<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="lessonContentSubjectSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="lessonContentSubjectSaveModal"
        centered
        size="xl"
        title="Thêm thông tin tiết học cho học phần"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <div class="text-right">

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  style="margin-right: 5px"
                  @click="onAddRow()"
                  size="sm"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    Thêm dòng
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col
              sm="8"
              md="6"
              lg="4"
              xl="2"
            >
              <b-form-group
                label="Số tiết thi"
                label-for="practiceCreditNum"
              >
                <b-form-input
                  v-model="practiceCreditNum"
                  type="number"
                  disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col cols="12">
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="lessonContentBySubject"
                :pagination-options="paginationOptions"
                class="my-table"
                style-class="my-table vgt-table striped bordered"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'name'">
                    <validation-provider
                      #default="{ errors }"
                      name="Bài học"
                    >
                      <b-form-input
                        v-model="props.row.name"
                        :tabindex="props.row.originalIndex + 1"
                        @change="changeCell(
                          props.row.name,
                          props.column.field,
                          props.row.originalIndex
                        )"
                        name="name"
                        placeholder="Bài học"
                        rows="3"
                        max-rows="5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <span v-else-if="props.column.field === 'theoryCreditNum'">
                    <b-form-input
                      v-model="props.row.theoryCreditNum"
                      :tabindex="props.row.originalIndex + 1"
                      @change="changeCell(
                          props.row.theoryCreditNum,
                          props.column.field,
                          props.row.originalIndex
                        )"
                      type="number"
                    />
                  </span>
                  <span v-else-if="props.column.field === 'note'">
                    <b-form-input
                        v-model="props.row.note"
                        :tabindex="props.row.originalIndex + 1"
                        @change="changeCell(
                          props.row.note,
                          props.column.field,
                          props.row.originalIndex
                        )"
                        name="note"
                        placeholder="Ghi chú"
                        rows="3"
                        max-rows="5"
                      />
                  </span>
                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteRow(props.row.originalIndex)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </span>
                  <!-- Column: Common -->
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
            <div class="w-100 d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="onSave(lessonContentBySubject)"
              >
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Lưu lại
                </span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('lessonContentSubjectSaveModal')"
              >
                <span class="text-right">
                  <feather-icon icon="XIcon" /> Hủy
                </span>
              </b-button>
            </div>
          </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormInput, BModal, BOverlay, BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { Flag } from '@/const/flag'

export default {
  name: 'SubjectSave',
  components: {
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    VueGoodTable,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    subjectName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      required,
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Bài học',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '45%',
        },
        {
          label: 'Số tiết lý thuyết, thảo luận',
          field: 'theoryCreditNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '30%',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      SubjectId: null,
      practiceCreditNum: null,
    }
  },
  computed: {
    ...mapGetters({
      lessonContentBySubject: 'subjectLessonContent/lessonContentBySubject',
    }),
  },
  methods: {
    ...mapActions({
      createData: 'subjectLessonContent/createData',
      saveLessonContentsByProjectSubject: 'subjectLessonContent/saveLessonContentsByProjectSubject',
      getLessonContentBySubjects: 'subjectLessonContent/getLessonContentBySubjects',
    }),
    lessonContentBySubjectUpdatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SUBJECT_LESSON)
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      this.subjectId = this.mainData.id
      this.practiceCreditNum = this.mainData.practiceCreditNum
      try {
        await this.getLessonContentBySubjects({
          subjectId: this.subjectId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.isLoading = false
    },
    changeCell(data, column, row) {
      this.lessonContentBySubject[row][column] = data
    },
    onDeleteRow(rowIndex) {
      this.lessonContentBySubject.splice(rowIndex, 1)
    },
    onAddRow() {
      const row = {
        name: '',
        code: '',
        status: Flag.ACTIVE,
        delFlag: Flag.NOT_DELETED,
        deleteMode: 'physical',
        subjectId: this.mainData.id,
        theoryCreditNum: '',
        note: '',
      }
      this.lessonContentBySubject.push(row)
    },
    onHide() {
      this.$refs
        .lessonContentSubjectSaveFormRef
        .reset()
    },
    async onSave(data) {
      if (!this.lessonContentBySubjectUpdatable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.name === null || element.name === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const updateLessonContentBySubject = data.map(element => ({
        id: element.id,
        name: element.name,
        note: element.note || null,
        theoryCreditNum: element.theoryCreditNum,
      }))
      try {
        const response = await this.saveLessonContentsByProjectSubject({
          subjectId: this.subjectId,
          updateLessonContentBySubject,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            try {
              await this.getLessonContentBySubjects({
                subjectId: this.subjectId,
              })
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
